<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import Button from "@/base-components/Button";
import Tab from "@/base-components/Headless/Tab";

import AssessmentsHero from "./components/Hero.vue";
import AssessmentsInformations from "./components/Informations.vue";
import AssessmentsObjectives from "./components/Objectives.vue";
import AssessmentsFeatures from "./components/Features.vue";
import AssessmentsDetails from "./components/Details.vue";
import AssessmentsSample from "./components/Sample.vue";
import AssessmentsReport from "./components/Report.vue";
import { useLocalize } from "@/locale/i18n";
import { toast } from "vue-sonner";
import { onMounted, watch, ref, provide, computed, watchEffect } from "vue";
import { initAssessments } from "./initialAssessment";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { GET_ASSESSMENT } from "@/lib/graphql/queries/assessment";
import LoadingSpinner from "@/components/Loading";
import { UPDATE_ASSESSMENTS } from "@/lib/graphql/mutations/assessment";
import SeoMetaFields from "@/base-components/SeoMetaFields/SeoMetaFields.vue";
import { useRouter } from "vue-router";
import { convertMetaTagsKeywords } from "@/lib/utils";

const { isRTL, i18n, language } = useLocalize();
const props = defineProps<{ id: string }>();
const router = useRouter();
const isEditing = computed<boolean>(() => !!props.id);

const AssessmentPageData = ref<IAssessmentsPage>(initAssessments());
const lang = ref<"en" | "ar">(language.value);

const objAccessor = computed<IAssessmentsObject>(() =>
  lang.value === "ar" ? "ArAssessmentsPage" : "EnAssessmentsPage"
);

type AssessmentResult = {
  getAssessmentPage: IAssessmentsPage;
};

let getAssessmentsPageLoading = ref(false);
if (isEditing.value) {
  const { result, loading } = useQuery<AssessmentResult>(
    GET_ASSESSMENT,
    { id: props.id },
    {
      fetchPolicy: "no-cache",
    }
  );
  getAssessmentsPageLoading = loading;

  watch(
    () => result.value,
    (res) => {
      if (res?.getAssessmentPage) {
        AssessmentPageData.value = {
          ...AssessmentPageData.value,
          ...res.getAssessmentPage,
          metaTags: convertMetaTagsKeywords(res.getAssessmentPage.metaTags),
        };
      }
    }
  );
}

provide("AssessmentPageData", AssessmentPageData);
provide("objAccessor", objAccessor);

const { mutate: setAssessmentsPage, loading: setAssessmentsPageLoading } =
  useMutation(UPDATE_ASSESSMENTS);

const handleSubmit = async () => {
  const data = { ...AssessmentPageData.value };
  window.scrollTo({ top: 120, behavior: "smooth" });

  try {
    isEditing.value
      ? await setAssessmentsPage({ data, id: props.id })
      : await setAssessmentsPage({ data });
    toast.success(i18n("common.updated"));
    router.push({ name: "website-assessments" });
  } catch (error: any) {
    if (error.message.includes("empty")) {
      toast.error(i18n("common.fillRequiredFields"));
    } else {
      toast.error(error.message);
    }

    // captureSentryError(error);
    console.log(error);
  }
};

const loading = computed(
  () => setAssessmentsPageLoading.value || getAssessmentsPageLoading.value
);

onMounted(async () => {
  document.title =
    i18n("routes.app") + " | " + i18n("routes.website-assessments");
});

watch(
  isRTL,
  () =>
    (document.title =
      i18n("routes.app") + " | " + i18n("routes.website-assessments"))
);
</script>

<template>
  <div class="mt-5">
    <div class="intro-y w-full">
      <!-- Blogs Informations title -->

      <!-- BEGIN: Form Layout -->
      <div class="p-5 intro-y box w-full">
        <div class="mb-4 intro-y border-b-2 pb-4 border-b-slate-100">
          <h2 class="text-base font-medium">
            {{ $t("routes.website-assessments") }}
          </h2>
        </div>

        <Tab.Group>
          <Tab.List variant="boxed-tabs">
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'en'">
                {{ $t("common.english") }}
              </Tab.Button>
            </Tab>
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'ar'">
                {{ $t("common.arabic") }}
              </Tab.Button>
            </Tab>
          </Tab.List>
        </Tab.Group>

        <div
          class="h-screen w-full flex items-center justify-center absolute"
          v-if="loading"
        >
          <LoadingSpinner />
        </div>

        <AssessmentsHero />

        <AssessmentsInformations />

        <AssessmentsObjectives />

        <AssessmentsFeatures />

        <AssessmentsDetails />

        <AssessmentsSample />

        <AssessmentsReport />

        <SeoMetaFields
          v-model="AssessmentPageData.metaTags[lang]"
          :isOptional="true"
        />

        <!--Hero paragraph En and Ar -->

        <!-- End About us Title En and Ar -->
        <div class="mt-20 text-right gap-2">
          <Button
            type="button"
            variant="outline-secondary"
            class="w-24 mr-1"
            @click="$router.push({ name: 'website-assessments' })"
          >
            {{ $t("common.cancel") }}
          </Button>
          <Button
            type="button"
            variant="primary"
            class="w-24"
            :disabled="loading"
            :isLoading="loading"
            @click="handleSubmit"
          >
            {{ $t("common.save") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
