import {definePreset} from "@primevue/themes"
import Aura from '@primevue/themes/aura';

const tawzefPreset = definePreset(Aura, {
    semantic: {
        primary: {
            50: '#F9CBCC',
  100: '#F7B8BA',
  200: '#F39496',
  300: '#EE6F72',
  400: '#EA4B4F',
  500: '#E6262B',
  600: '#BE161A',
  700: '#8C1013',
  800: '#590A0C',
  900: '#270505',
  950: '#0E0202'
        }
    }
});

export default tawzefPreset;