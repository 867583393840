import gql from "graphql-tag"

export const SET_KEYWORDS= gql`
mutation UpdateKeyWord($keyWord: KeyWordsInput!, $updateKeyWordId: String) {
  updateKeyWord(keyWord: $keyWord, id: $updateKeyWordId) {
    id
		keyword
  }
}

`
