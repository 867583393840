import gql from "graphql-tag";
import { ImageFragment, MetaTagsFragment } from "./base-fragments";

export const ContentBlogFragments = gql`
  fragment ContentBlogFragments on ContentBlog {
    title
    body
    createdAt
    slug
    image {
      ...ImageFragment
    }
    thumbnail {
      ...ImageFragment
    }
  }
  ${ImageFragment}
`;

export const BlogFragment = gql`
  fragment BlogFragment on Blog {
    id
    en {
      ...ContentBlogFragments
    }
    ar {
      ...ContentBlogFragments
    }
    metaTags {
      ...MetaTagsFragment
    }
    writenBy
    categories
  }
  ${MetaTagsFragment}
  ${ContentBlogFragments}
`;
