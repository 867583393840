<script setup lang="ts">
import Button from "@/base-components/Button";
import { useLocalize } from "@/locale/i18n";
import Tab from "@/base-components/Headless/Tab";
import LoadingSpinner from "@/components/Loading";
import { onMounted, watch, ref, provide, computed, watchEffect } from "vue";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { GET_RECORDS } from "@/lib/graphql/queries/records";
import { SET_RECORDS } from "@/lib/graphql/mutations/records";
import { toast } from "vue-sonner";
import ServiceRecordsHeroParagraph from "./Components/ServiceRecordsHeroParagraph.vue";
import ServiceRecordsCallCenter from "./Components/ServiceRecordsCallCenter.vue";
import ServiceRecordManpower from "./Components/ServiceRecordManpower.vue";
import ServiceRecordPayroll from "./Components/ServiceRecordPayroll.vue";

import { initialRecordPage } from "./initialRecord";
// Localization
const { isRTL, i18n, language } = useLocalize();

// const recordData = ref<IOutsourcingPage>(initialOutsourcingPage);
const lang = ref<"en" | "ar">(language.value);

const objAccessor = computed<IRecordObject>(() =>
  lang.value === "ar" ? "ArOutsourcingPage" : "EnOutsourcingPage"
);

type outSourcingResult = {
  getEmployerOfRecordsPage: IRecordPage;
};

// Fetch HomePage
const { result: recordPage, loading: getOutsourcingLoading } =
  useQuery<outSourcingResult>(GET_RECORDS, null, {
    fetchPolicy: "no-cache",
  });

const recordData = ref<IRecordPage>(initialRecordPage);
watchEffect(() => {
  if ( recordPage.value ) {
    console.log(recordPage.value, "Full GraphQL Response");

        console.log("API Response:", recordPage.value.getEmployerOfRecordsPage);
    recordData.value = {
      ...recordData.value,
      ...recordPage.value.getEmployerOfRecordsPage
    };
  }
});

provide( "recordData", recordData );
console.log(recordData.value, "recordData");

provide("objAccessor", objAccessor);

// Submit Data
const { mutate: setRecordPage, loading: setOutSourcingLoading } =
  useMutation(SET_RECORDS);
const handleSubmit = async () => {
  const data = { ...recordData.value };
  delete data.metaTags;
  window.scrollTo({
    top: 120,
    behavior: "smooth",
  });
  try {
    await setRecordPage({ data });
    toast.success(i18n("common.updated"));
  } catch (error: any) {
    toast.error(error.message);
  }
};

const loading = computed(
  () => setOutSourcingLoading.value || getOutsourcingLoading.value
);

onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.website-home");
});

watch(
  isRTL,
  () =>
    (document.title = i18n("routes.app") + " | " + i18n("routes.website-home"))
);
</script>
<template>
  <div class="mt-5">
    <div class="intro-y w-full">
      <!-- Blogs Informations title -->

      <!-- BEGIN: Form Layout -->
      <div class="p-5 intro-y box w-full">
        <!-- Hero section title -->
        <div class="mb-4 intro-y border-b-2 pb-4 border-b-slate-100">
          <h2 class="text-base font-medium">
            {{ $t("website.services.record.title") }}
          </h2>
          <p class="text-slate-400 text-xs mt-1 font-normal">
            {{ $t("website.services.outsourcing.description") }}
          </p>
        </div>
        <!-- end of  hero section title -->
        <Tab.Group>
          <Tab.List variant="boxed-tabs">
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'en'">
                {{ $t("common.english") }}
              </Tab.Button>
            </Tab>
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'ar'">
                {{ $t("common.arabic") }}
              </Tab.Button>
            </Tab>
          </Tab.List>
        </Tab.Group>
        <div
          class="h-screen w-full flex items-center justify-center absolute"
          v-if="loading"
        >
          <LoadingSpinner />
        </div>

        <ServiceRecordsHeroParagraph />
				<ServiceRecordManpower />
				  <ServiceRecordPayroll />
      <ServiceRecordsCallCenter />
        

      

      

        <div class="mt-20 justify-end flex gap-2">
          <!-- <Button type="button" variant="outline-secondary" class="w-24 mr-1">
            {{ $t("common.cancel") }}
          </Button> -->
          <Button
            type="button"
            variant="primary"
            class="w-24"
            :disabled="loading"
            :isLoading="loading"
            @click="handleSubmit"
          >
            {{ $t("common.save") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>