<script setup lang="ts">
import { GET_BLOG_CATEGORY } from "@/lib/graphql/queries/blogsCategories";
import { useLocalize } from "@/locale/i18n";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { computed, onMounted, watch, ref } from "vue";
import { createNewCategory } from "./categories";
import LoadingSpinner from "@/components/Loading";
import Button from "@/base-components/Button";
import { FormInput, FormLabel } from "@/base-components/Form";
import SeoMetaFields from "@/base-components/SeoMetaFields/SeoMetaFields.vue";
import Tab from "@/base-components/Headless/Tab";

import { UPDATE_BLOG_CATEGORY } from "@/lib/graphql/mutations/blogsCategories";
import { useRouter } from "vue-router";
import { toast } from "vue-sonner";

import { inject, ComputedRef, provide } from "vue";
import { convertMetaTagsKeywords } from "@/lib/utils";

// Localization
const { isRTL, i18n, language } = useLocalize();

const categoryData1 = inject<ComputedRef<IBlogCategory>>("categoryData")!;

const props = defineProps<{ id: string }>();
const lang = ref<"en" | "ar">(language.value);

const isEditing = computed<boolean>(() => !!props.id);

const router = useRouter();

type CategoryApi = {
  getCategory: IClient;
};

const categoryData = ref(createNewCategory());

let getCategoryLoading = ref(false);

if (isEditing.value) {
  const { result, loading } = useQuery<CategoryApi>(
    GET_BLOG_CATEGORY,
    { categoryId: props.id },
    {
      fetchPolicy: "no-cache",
    }
  );
  getCategoryLoading = loading;

  watch(
    () => result.value,
    (res) => {
      if (res?.getCategory) {
        categoryData.value = {
          ...categoryData.value,
          ...res.getCategory,
          metaTags: convertMetaTagsKeywords(res.getCategory.metaTags)
        };
      }
    }
  );
}

const { mutate: updateCategory, loading: updateCategoryLoading } =
  useMutation(UPDATE_BLOG_CATEGORY);

const handleSubmit = async () => {
  const data = { ...categoryData.value };
  delete data.updatedAt;
  delete data.createdAt;
  delete data.id;
  try {
    isEditing.value
      ? await updateCategory({ data, categoryId: props.id })
      : await updateCategory({ data });
    toast.success(i18n("common.updated"));
    router.push({ name: "website-blogsCategories" });
  } catch (error: any) {
    toast.error(error.message);
  }
};

const loading = computed<boolean>(
  () => getCategoryLoading.value || updateCategoryLoading.value
);

onMounted(async () => {
  document.title =
    i18n("routes.app") + " | " + i18n("routes.website-blogsCategories");
});
watch(
  isRTL,
  () =>
    (document.title =
      i18n("routes.app") + " | " + i18n("routes.website-blogsCategories"))
);

//SLUG
const blogUrl = import.meta.env.VITE_WEBSITE_URL;
function generateSlug(name: string) {
  const slug = name
    .toLowerCase()
    .trim()
    .replace(/[^\u0600-\u06FFa-z0-9\s-]/g, "") //  Remove all non-alphanumeric characters except spaces and hyphens, including Arabic characters
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/-+/g, "-"); // Replace multiple hyphens with a single hyphen
  categoryData.value.slug[lang.value] = slug;
}

function generateBlogLink(slug: string) {
  const blogLink = blogUrl + "/blogs/category/" + slug;
  window.open(blogLink, "_blank");
  // console.log(blogLink, "blogLink");
}
</script>

<template>
  <div class="mt-5">
    <div class="intro-y w-full">
      <div class="p-5 intro-y box w-full">
        <div class="mb-4 intro-y border-b-2 pb-4 border-b-slate-100">
          <h2 class="text-base font-medium">
            {{ $t("routes.blogsCategories") }}
          </h2>
        </div>

        <Tab.Group>
          <Tab.List variant="boxed-tabs">
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'en'">
                {{ $t("common.english") }}
              </Tab.Button>
            </Tab>
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'ar'">
                {{ $t("common.arabic") }}
              </Tab.Button>
            </Tab>
          </Tab.List>
        </Tab.Group>

        <div
          class="h-full w-full flex items-center justify-center absolute z-50"
          v-if="loading"
        >
          <LoadingSpinner />
        </div>

        <div class="grid-cols-2 gap-3 mt-2 sm:grid">
          <div>
            <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
              {{ $t("common.name") }}
            </FormLabel>
            <FormInput
              @input="generateSlug(categoryData.name[lang])"
              id="crud-form-1"
              type="text"
              class="w-full"
              v-model="categoryData.name[lang]"
            />
          </div>
          <div>
            <FormLabel htmlFor="crud-form-1"> {{ $t("blogs.slug") }}</FormLabel>
            <div class="flex items-center">
              <button
                @click="generateBlogLink(categoryData.slug[lang])"
                class="bg-zinc-200 text-xs h-9 text-slate-600 ps-3 pe-1 py-1.5 rounded-s-md border border-slate-300 border-e-0"
              >
                {{ blogUrl+"blogs/category/" }}
              </button>
              <FormInput
                @input="generateSlug(categoryData.slug[lang])"
                type="text"
                name=""
                id=""
                v-model="categoryData.slug[lang]"
                class="border-s-0 focus:outline-none h-9 border-slate-300 focus:ring-0 rounded-s-none"
              />
            </div>
          </div>
        </div>

        <SeoMetaFields v-model="categoryData.metaTags[lang]" />

        <div class="mt-5 text-right">
          <Button
            type="button"
            variant="outline-secondary"
            class="w-24 mr-1"
            @click="$router.push({ name: 'website-blogsCategories' })"
          >
            {{ $t("common.cancel") }}
          </Button>
          <Button
            type="button"
            variant="primary"
            class="w-24"
            :disabled="loading"
            :isLoading="loading"
            @click="handleSubmit"
          >
            {{ $t("common.save") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
