import gql from "graphql-tag"
import {
	ButtonFragment,
	ImageFragment,
	MetaTagsFragment,
} from "./base-fragments"

export const OutsourcingManPowerFragment = gql`
  fragment OutsourcingManPowerFragment on OutsourcingManPower {
    images {
      ...ImageFragment
    }
    paragraphs
    title
    description
    buttons {
      ...ButtonFragment
    }
  }
  ${ ButtonFragment }
  ${ ImageFragment }
`

export const OutsourcingPayrollFragment = gql`
  fragment OutsourcingPayrollFragment on OutsourcingPayroll {
    title
    description
    paragraphs
    buttons {
      ...ButtonFragment
    }
  }
  ${ ButtonFragment }
`

export const OutsourcingCallCenterFragment = gql`
  fragment OutsourcingCallCenterFragment on OutsourcingCallCenter {
    title
    description
    paragraphs
    buttons {
      ...ButtonFragment
    } 
    images {
      ...ImageFragment
    }
  }
  ${ ImageFragment }
`

export const ContentOutsourcingPageFragment = gql`
  fragment ContentOutsourcingPageFragment on ContentOutsourcingPage {
    title
    paragraph
    button {
      ...ButtonFragment
    }
    manPower {
      ...OutsourcingManPowerFragment
    }
    payroll {
      ...OutsourcingPayrollFragment
    }
    callCenter {
      ...OutsourcingCallCenterFragment
    }
  }
  ${ ButtonFragment }
  ${ ImageFragment }
  ${ OutsourcingManPowerFragment }
  ${ OutsourcingPayrollFragment }
  ${ OutsourcingCallCenterFragment }
`

export const recordFragment = gql`
  fragment recordFragment on OutsourcingPage {
    EnOutsourcingPage {
      ...ContentOutsourcingPageFragment
    }
    ArOutsourcingPage {
      ...ContentOutsourcingPageFragment
    }
    metaTags {
      ...MetaTagsFragment
    }
  }
  ${ ContentOutsourcingPageFragment }
  ${ MetaTagsFragment }
`
