<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import Button from "@/base-components/Button";

import { useI18n } from "vue-i18n";
////////////////////////////////////////////////////////////

import { useLocalize } from "@/locale/i18n";

import Tab from "@/base-components/Headless/Tab";
import SeoMetaFields from "@/base-components/SeoMetaFields/SeoMetaFields.vue";
import LoadingSpinner from "@/components/Loading";
import { useRoute, useRouter } from "vue-router";

import PartnersTitleMedia from "./Components/PartnersTitleMedia.vue";
import PartnersAbout from "./Components/PartnersAbout.vue";
import PartnersServices from "./Components/PartnersServices.vue";
import PartnerSocialLinks from "./Components/PartnerSocialLinks.vue";
import { onMounted, watch, ref, provide, computed, watchEffect } from "vue";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { GET_PARTNER_PAGE } from "@/lib/graphql/queries/partners";
import { UPDATE_PARTNER } from "@/lib/graphql/mutations/partners";
import { initialPartnersPage } from "./initialPartners";
import { toast } from "vue-sonner";
import useSentry from "@/composables/useSentry";
import { convertMetaTagsKeywords } from "@/lib/utils";

const { captureSentryError } = useSentry();

const { isRTL, i18n, language } = useLocalize();
const props = defineProps<{ id: string }>();
const router = useRouter();

const isEditing = computed<boolean>(() => !!props.id);

const lang = ref<"en" | "ar">(language.value);

const objAccessor = computed<IPartnersObject>(() =>
  lang.value === "ar" ? "ArPartnersPage" : "EnPartnersPage"
);

// const partnersPageData = ref<IPartnersPage>({ ...initialPartnersPage });

const partnersPageData = ref<IPartnersPage>(initialPartnersPage());
type PartnerResult = {
  getPartnerPage: IPartnersPage;
};

let getPartnerPageLoading = ref(false);
// partner/id
if (isEditing.value) {
  const { result, loading } = useQuery<PartnerResult>(
    GET_PARTNER_PAGE,
    { id: props.id },
    {
      fetchPolicy: "no-cache",
    }
  );
  getPartnerPageLoading = loading;

  watch(
    () => result.value,
    (res) => {
      if (res?.getPartnerPage) {
        partnersPageData.value = {
          ...partnersPageData.value,
          ...res.getPartnerPage,
          metaTags: convertMetaTagsKeywords(res.getPartnerPage.metaTags),
        };
      }
    }
  );
}

provide("partnersPageData", partnersPageData);
provide("objAccessor", objAccessor);

// Submit Data
const { mutate: setPartnerPage, loading: setPartnerPageLoading } =
  useMutation(UPDATE_PARTNER);
const handleSubmit = async () => {
  const data = { ...partnersPageData.value };
  try {
    window.scrollTo({ top: 120, behavior: "smooth" });
    isEditing.value
      ? await setPartnerPage({ data, id: props.id })
      : await setPartnerPage({ data });
    toast.success(i18n("common.updated"));
    router.push({ name: "website-partners" });
  } catch (error: any) {
    if (error.message.includes("empty")) {
      toast.error(i18n("common.fillRequiredFields"));
    } else {
      toast.error(error.message);
    }

    // captureSentryError(error);
    console.log(error);
  }
};

const loading = computed(
  () => getPartnerPageLoading.value || setPartnerPageLoading.value
);

onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.website-partners");
});
watch(
  isRTL,
  () =>
    (document.title =
      i18n("routes.app") + " | " + i18n("routes.website-partners"))
);
</script>

<template>
  <div class="mt-5">
    <div class="intro-y w-full">
      <!-- Blogs Informations title -->
      <!-- BEGIN: Form Layout -->
      <div class="p-5 intro-y box w-full">
        <div class="mb-4 intro-y border-b-2 pb-4 border-b-slate-100">
          <h2 class="text-base font-medium">
            {{ $t("common.partner") }}
          </h2>
          <p class="text-slate-400 text-xs mt-1 font-normal">
            {{ $t("website.services.description") }}
          </p>
        </div>
        <Tab.Group>
          <Tab.List variant="boxed-tabs">
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'en'">
                {{ $t("common.english") }}
              </Tab.Button>
            </Tab>
            <Tab>
              <Tab.Button class="w-full py-2" as="button" @click="lang = 'ar'">
                {{ $t("common.arabic") }}
              </Tab.Button>
            </Tab>
          </Tab.List>
        </Tab.Group>
        <br />
        <div
          class="h-screen w-full flex items-center justify-center absolute z-[1000]"
          v-if="loading"
        >
          <LoadingSpinner />
        </div>

        <PartnersTitleMedia />

        <PartnersAbout />

        <PartnersServices />

        <PartnerSocialLinks />

        <SeoMetaFields
          v-model="partnersPageData.metaTags[lang]"
          :isOptional="true"
        />

        <!-- End About us Title En and Ar -->
        <div class="mt-20 text-right">
          <Button
            type="button"
            variant="outline-secondary"
            class="w-24 mr-1"
            @click="$router.push({ name: 'website-partners' })"
          >
            {{ $t("common.cancel") }}
          </Button>
          <Button
            type="button"
            variant="primary"
            class="w-24"
            :disabled="loading"
            :isLoading="loading"
            @click="handleSubmit"
          >
            {{ $t("common.save") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
