<script setup lang="ts">
import { defineProps, defineEmits } from "vue";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import Button from "@/base-components/Button";
const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  keyword: {
    type: String,
    required: true,
  },
  places: {
    type: Array as () => { name: string; url: string }[],
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits<{
  (e: "close"): void;
}>();

const closeModal = () => {
  emit("close");
};
</script>
<template>
  <Dialog v-model:open="props.open">
    <DialogContent class="sm:max-w-[425px]" @close="closeModal">
      <DialogHeader>
        <DialogTitle>{{ $t('keyword.placesFor') }} {{ props.keyword }}</DialogTitle>
      </DialogHeader>
      <DialogDescription>
        <div v-if="props.loading" class="text-center my-4">
          <div
            class="spinner-border animate-spin inline-block w-8 h-8 border-4 border-current border-t-transparent text-blue-600 rounded-full"
            role="status"></div>
        </div>
        <ul v-else class="flex flex-col gap-3 w-full max-h-[500px] overflow-auto">
          <li v-for="place in props.places" :key="place.url" class="w-full ">
            <a :href="place.url" target="_blank" rel="noopener noreferrer"
              class="text-primary hover:bg-danger transition-all hover:text-gray-100 cursor-pointer bg-gray-100 p-3 block w-full">
              {{ place.name }}
            </a>
          </li>
        </ul>

      </DialogDescription>
      <DialogFooter>
        <Button variant="primary" @click="closeModal">Close</Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
