<script setup lang="ts">
import { ref, computed, watch } from "vue";
import TomSelect from "@/base-components/TomSelect";
import { GET_KEYWORDS_NAMES } from "@/lib/graphql/queries/keywords";
import { SET_KEYWORDS } from "@/lib/graphql/mutations/keywords";
import { useMutation, useQuery, useLazyQuery } from "@vue/apollo-composable";
import Loading from "@/components/Loading/Loading.vue";
import MultiSelect from "primevue/multiselect"
import { toast } from "vue-sonner";
import { t } from "@/locale/i18n";

interface Keywords {
  id: string;
  keyword: string;
}


const props = defineProps({
  modelValue: {
    type: Array,
    required: true,
  },
})
const emit = defineEmits(["update:modelValue"]);

const values = ref(props.modelValue);
watch(
  () => values.value,
  () => {
    emit("update:modelValue", values.value);
  })

const { result, loading,refetch } = useQuery(GET_KEYWORDS_NAMES, {
  fetchPolicy: "no-cache",
});

const { mutate: updateKeyWord } = useMutation(SET_KEYWORDS);

const keywords = computed<Keywords[]>(() => {
  return result.value?.getKeyWords?.keywords || [];
});

const searchTerm = ref<string>("");



const addKeyword = async (keyWord: string) => {
  if (!keyWord || keyWord?.trim()?.length === 0) {
    toast.error(t('keyword.pleaseEnterAKeyword'));
  };
  console.log("addKeyword", keyWord);
  if (keywords.value.some((keyword) => keyword.keyword === keyWord)) {
    return;
  }
  try {
    loading.value = true;
    const updatedKeyword = await updateKeyWord({
      keyWord: { keyword: keyWord },
    });
    await refetch();
    const newKeyword = updatedKeyword?.data?.updateKeyWord;
    console.log("newKeyword", newKeyword);
    const addedKeywordIndex = values.value.findIndex((keyword) => {
      if (typeof keyword === "string") {
        return keyword === newKeyword?.keyword;
      } else {
        return keyword.keyword === newKeyword?.keyword;
      }
    })
    console.log("addedKeywordIndex", addedKeywordIndex);
    if (addedKeywordIndex === -1) {
      values.value.push(newKeyword.id);
    } else {
      values.value[addedKeywordIndex] = newKeyword.id;
    }
    searchTerm.value = "";
    console.log("updated keyword", updatedKeyword);
  } catch (error) {
    console.error("Failed to add new keyword: ", error);
  } finally {
    loading.value = false;
  }
};
</script>

<template>
  <MultiSelect 
    :disabled="loading"
    :autoFilterFocus="true"
    :resetFilterOnClear="true"
    :loading="loading"
    display="chip"
    v-model="values" 
    :options="keywords" 
    optionValue="id" 
    optionLabel="keyword" 
    :virtualScrollerOptions="{ itemSize: 40 }"
    :showToggleAll="false"
    filter 
    @filter="(filterData) => searchTerm = filterData.value"
    class="w-full">
    <template #footer>
      <Loading size="20px" v-if="loading" />
        <p v-else-if="searchTerm?.length" class="px-4 py-2 bg-red-600 text-white hover:bg-red-700 cursor-pointer"  @click="addKeyword(searchTerm)">
          {{ $t('keyword.keywordNotFound') }}
          {{ $t('common.add') }} {{ searchTerm }}
        </p>
      </template>
  </MultiSelect>
</template>
