import {
	recordPage,
	SocialType,
} from "@/types/website/services/record"

export const initialRecordPage: recordPage = {
	EnOutsourcingPage: {
		title: "",
		paragraph: "",
		button: {
			text: "",
			link: "",
		},
		manPower: {
			title: '', //
			description: '',
			images: [
				{
					alt: "",
					caption: "",
					description: "",
					title: "",
					url: "",
				},
			],
			paragraphs: [ "" ],
			buttons: [
				{
					text: "",
					link: "",
				},
				{
					text: "",
					link: "",
				},
			],
		},
		payroll: {
			title: "",
			description: '', //
			paragraphs: [ "" ],
			buttons: [
				{
					text: "",
					link: "",
				},
				{
					text: "",
					link: "",
				},
			],
		},
		callCenter: {
			title: "",
			description: '', //

			images: [
				{
					alt: "",
					caption: "",
					description: "",
					title: "",
					url: "",
				},
			],
			buttons: [ //
				{
					text: "",
					link: "",
				},
				{
					text: "",
					link: "",
				},
			],
			paragraphs: [ "" ],
		},
	},
	ArOutsourcingPage: {
		title: "",
		paragraph: "",
		button: {
			text: "",
			link: "",
		},
		manPower: {
			title: '',
			description: '',
			images: [
				{
					alt: "",
					caption: "",
					description: "",
					title: "",
					url: "",
				},
			],
			paragraphs: [ "" ],
			buttons: [
				{
					text: "",
					link: "",
				},
				{
					text: "",
					link: "",
				},
			],
		},
		payroll: {
			title: "",
			description: '',
			paragraphs: [ "" ],
			buttons: [
				{
					text: "",
					link: "",
				},
				{
					text: "",
					link: "",
				},
			],
		},
		callCenter: {
			title: "",
			description: '',
			images: [
				{
					alt: "",
					caption: "",
					description: "",
					title: "",
					url: "",
				},
			],
			buttons: [
				{
					text: "",
					link: "",
				},
				{
					text: "",
					link: "",
				},
			],
			paragraphs: [ "" ],
		},
	},
	
}

export default initialRecordPage
