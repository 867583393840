
// TABLE COLUMNS
export const columns = [
  {
    field: "keyword",
    label: "keyword.keyword",
    type: "string",
  },
  
  {
    field: "count",
    label: "keyword.usedCount",
    type: "truncatedText",
  },
  {
    field: "action",
    label: "keyword.placesUsed",
    type: "action",
    align:"end",
    ignorePrint: "ignore-0",
    ignorePrintRow: "ignore-00",
  },
];
