<script setup lang="ts">
import { GET_CURRENT_MEMBER, GET_MEMBER } from "@/lib/graphql/queries/members";
import { useLocalize } from "@/locale/i18n";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { ref, watch, onMounted } from "vue";
import { inject, ComputedRef } from "vue";
import { UPDATE_ME, UPDATE_MEMBER } from "@/lib/graphql/mutations/members";
import { computed } from "vue";
import { FormInput } from "@/base-components/Form";
import Button from "@/base-components/Button";
import LoadingSpinner from "@/components/Loading";
import { getInitialMember } from "./initialMember";
import { useRoute } from "vue-router";
import { CurrentMemeberApi } from "@/types/member";
import ChangePassword from "@/components/Modals/ChangePassword.vue";
import TomSelect from "@/base-components/TomSelect";
import { toast } from "vue-sonner";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores/auth/auth-store";

import usePermissions from "@/composables/usePermissions";
import router from "@/router";
import SeoMetaFields from "@/base-components/SeoMetaFields/SeoMetaFields.vue";
import { current } from "tailwindcss/colors";

console.log(router.currentRoute.value.path, "hdhdhd");
const urlPage = ref(router.currentRoute);
console.log(urlPage.value.path, "urlPage");

const { Roles } = usePermissions();

// Localization
const { isRTL, i18n } = useLocalize();

const route = useRoute();

const props = defineProps<{ id: string }>();

const memberData = ref<IMember>(getInitialMember());
const isProfile = computed<boolean>(() => route.path.includes("profile"));

type MemeberApi = {
  getMember: IMember;
};
// get_current_member , ana bgeb nfsy
// get_member , admin bygeb member mo3yn
const { result, loading: getLoading } = isProfile.value
  ? useQuery<CurrentMemeberApi>(GET_CURRENT_MEMBER, null, {
      fetchPolicy: "no-cache",
    })
  : useQuery<MemeberApi>(
      GET_MEMBER,
      { memberId: props.id },
      {
        fetchPolicy: "no-cache",
      }
    );

watch(
  () => result.value,
  (res) => {
    const resData = isProfile.value
      ? (res as CurrentMemeberApi).memberFetchMe
      : (res as MemeberApi).getMember;

    memberData.value = {
      ...memberData.value,
      ...(resData || {}),
      metaTags: convertMetaTagsKeywords(resData.metaTags),
    };
    // temp solution
    memberData.value[objAccessor.value].profilePic ||= getInitialMember().en.profilePic;
  }
);

const { mutate: updateMember, loading: updateMemberLoading } =
  useMutation(UPDATE_MEMBER);

const { mutate: updateCurrentMember, loading: updateCurrentMemberLoading } =
  useMutation(UPDATE_ME);

//SLUG
const tawzefDomain = import.meta.env.VITE_WEBSITE_URL;
function generateSlug(title: string) {
  const slug = title
    .toLowerCase()
    .trim()
    .replace(/[^\u0600-\u06FFa-z0-9\s-]/g, "") //  Remove all non-alphanumeric characters except spaces and hyphens, including Arabic characters
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/-+/g, "-"); // Replace multiple hyphens with a single hyphen
  memberData.value[objAccessor.value].slug = slug;
}

function generateBlogLink(slug: string) {
  const blogLink = tawzefDomain + "member/" + slug;
  window.open(blogLink, "_blank");
}

const handleSubmit = async () => {
  const {
    linkedin,

    role,
    metaTags,
    phoneNumber_1,
    en,
    ar,

    // order,
  } = { ...memberData.value };
  console.log(metaTags);
  const data =
    urlPage.value.path == "/en/profile"
      ? {
          en: {
            fullName: memberData.value.en.fullName,
            title: memberData.value.en.title,
            slug: memberData.value.en.slug,
          brief: memberData.value.en.brief,
                profilePic: memberData.value.en.profilePic,
          },
          ar: {
            fullName: memberData.value.ar.fullName,
            title: memberData.value.ar.title,
            slug: memberData.value.ar.slug,
            brief: memberData.value.ar.brief,
            profilePic: memberData.value.ar.profilePic,
          },
          linkedin,
          phoneNumber_1,

          // role,
          // order,
        }
      : {
          en: {
            fullName: memberData.value.en.fullName,
            title: memberData.value.en.title,
            slug: memberData.value.en.slug,
          brief: memberData.value.en.brief,
            profilePic: memberData.value.en.profilePic,
          },
          ar: {
            fullName: memberData.value.ar.fullName,
            title: memberData.value.ar.title,
            slug: memberData.value.ar.slug,
            brief: memberData.value.ar.brief,
            profilePic: memberData.value.ar.profilePic,
          },
          linkedin,
          role,
          metaTags,
          phoneNumber_1,
          // order,
        };

  try {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    isProfile.value
      ? await updateCurrentMember({ data })
      : await updateMember({ data, memberId: props.id });
      
    toast.success(i18n("common.updated"));
  } catch (error: any) {
    console.dir(error.message);
    toast.error(error.message);
  }
};

const loading = computed(
  () =>
    getLoading.value ||
    updateMemberLoading.value ||
    updateCurrentMemberLoading.value
);

//UPDATE ROLEEES
const placeholder = computed<string>(() => i18n("members.selectRole"));
const { currentUser } = storeToRefs(useAuthStore());

const hasPermessionToUpdateRole = computed(() => {
  return currentUser.value?.role === Roles.ADMINISTRATOR;
});

console.log(currentUser.value?.role, "hdshdsh");

const disableUpdateMyRole = computed(() => {
  return currentUser.value?.id === memberData.value?.id;
});

onMounted(async () => {
  document.title = i18n("routes.app") + " | " + i18n("routes.members");
});
watch(
  isRTL,
  () => (document.title = i18n("routes.app") + " | " + i18n("routes.members"))
);

const { language } = useLocalize();

const lang = ref<"en" | "ar">(language.value);

const memberMetaTags = ref();
const metaTagObject = getInitialMember().metaTags.en;
console.log(metaTagObject, "its meta tags");

watch(
  [() => memberData.value.metaTags, () => lang.value],
  () => {
    memberMetaTags.value = memberData.value.metaTags[lang.value] ?? {
      ...metaTagObject,
    };
  },
  {
    deep: true,
    immediate: true,
  }
);

watch(
  memberMetaTags,
  () => {
    memberData.value.metaTags[lang.value] = memberMetaTags.value;
  },
  {
    deep: true,
    immediate: true,
  }
);
import Tab from "@/base-components/Headless/Tab";
import { Ref, provide } from "vue";
import { FullPage } from "ckeditor5";
import { convertMetaTagsKeywords } from "@/lib/utils";

// const objAccessor = inject<
//   ComputedRef<IMemberObject> | Ref<IMemberObject>
//   >("objAccessor", "EnAssessmentsPage");

const objAccessor = computed<IMemberObject>(() =>
  lang.value === "ar" ? "ar" : "en"
);

provide("objAccessor", objAccessor);
</script>

<template>
  <div class="bg-white rounded-lg relative">
    <div
      class="h-screen w-full flex items-center justify-center absolute z-50"
      v-if="loading"
    >
      <LoadingSpinner />
    </div>

    <div class="flex items-center mt-8 pt-8 px-5 intro-y justify-between">
      <h2 class="text-lg font-medium">
        {{ $t("profile.member") }}
      </h2>
      <ChangePassword />
    </div>
    <br /><br />
    <Tab.Group>
      <Tab.List variant="boxed-tabs">
        <Tab>
          <Tab.Button class="w-full py-2" as="button" @click="lang = 'en'">
            {{ $t("common.english") }}
          </Tab.Button>
        </Tab>
        <Tab>
          <Tab.Button class="w-full py-2" as="button" @click="lang = 'ar'">
            {{ $t("common.arabic") }}
          </Tab.Button>
        </Tab>
      </Tab.List>
    </Tab.Group>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <!-- BEGIN: Profile Menu -->
      <!-- END: Profile Menu -->
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Personal info -->
          <div class="col-span-12 intro-y bg-white">
            <div
              class="flex items-center px-5 py-5 border-b sm:py-5 border-slate-200/60 dark:border-darkmode-400"
            >
              <h2 class="text-base font-medium">
                {{ $t("profile.PersonalInfo") }}
              </h2>
            </div>
            <div
              class="p-5 grid gap-5 grid-cols-[repeat(auto-fit,minmax(250px,1fr))]"
            >
              <div class="relative flex w-full">
                <div class="flex flex-col gap-2 w-full">
                  <label class="font-semibold text-[#404553]" for="">{{
                    $t("profile.fullname")
                  }}</label>
                  <FormInput
                    v-model="memberData[objAccessor].fullName"
                    type="text"
                    class="border-[#dadce3] placeholder:text-sm rounded py-3"
                  />
                </div>
              </div>
              <div class="relative flex w-full">
                <div class="flex flex-col gap-2 w-full">
                  <label class="font-semibold text-[#404553]" for="">
                    {{ $t("profile.title") }}</label
                  >
                  <FormInput
                    @input="generateSlug(memberData[objAccessor].title)"
                    v-model="memberData[objAccessor].title"
                    type="text"
                    class="border-[#dadce3] placeholder:text-sm rounded py-3"
                  />
                </div>
              </div>
              <div class="relative flex w-full">
                <div class="flex flex-col gap-2 w-full">
                  <label class="font-semibold text-[#404553]" for="">
                    {{ $t("profile.email") }}</label
                  >
                  <FormInput
                    v-model="memberData.email"
                    disabled
                    type="email"
                    class="border-[#dadce3] placeholder:text-sm rounded py-3 bg-gray-100 cursor-not-allowed"
                  />
                </div>
              </div>
              <div class="flex flex-col gap-2 w-full">
                <label class="font-semibold text-[#404553]" for="">
                  {{ $t("blogs.slug") }}</label
                >
                <div class="flex items-center">
                  <button
                    @click="generateBlogLink(memberData[objAccessor].slug)"
                    class="bg-zinc-200 text-xs h-9 text-slate-600 ps-3 pe-1 py-1.5 rounded-s-md border border-slate-300 border-e-0"
                  >
                    {{ tawzefDomain + "member/" }}
                  </button>
                  <FormInput
                    @input="generateSlug(memberData[objAccessor].slug)"
                    type="text"
                    name=""
                    id=""
                    v-model="memberData[objAccessor].slug"
                    class="border-s-0 focus:outline-none h-9 border-slate-300 focus:ring-0 rounded-s-none"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- ROLE -->

          <div
            class="col-span-full md:col-span-6 mx-4"
            v-show="urlPage.path != '/en/profile'"
          >
            <div class="flex flex-col gap-2 w-full" v-if="!disableUpdateMyRole">
              <label class="font-semibold text-[#404553]" for="">{{
                $t("common.role")
              }}</label>
              <TomSelect
                v-model="memberData.role"
                :options="{
                  placeholder,
                }"
                :disabled="!hasPermessionToUpdateRole"
              >
                <option v-for="role in Roles" :key="role" :value="role">
                  {{ role }}
                </option>
              </TomSelect>
            </div>
          </div>
          <!-- END OF ROLE -->

          <!-- END: Personal info -->
          <!-- BEGIN: optional info  -->
          <div class="col-span-12 flex gap-5 flex-wrap sm:flex-nowrap">
            <div class="intro-y bg-white basis-full pb-5">
              <div
                class="flex items-center px-5 py-5 border-b sm:py-5 border-slate-200/60 dark:border-darkmode-400"
              >
                <h2 class="text-base font-medium">
                  {{ $t("profile.optionalInfo") }}
                </h2>
              </div>
              <div
                class="p-3 md:p-5 pb-10 grid gap-5 grid-cols-[repeat(auto-fit,minmax(250px,1fr))]"
              >
                <div class="relative flex w-full">
                  <div class="flex flex-col gap-2 w-full">
                    <label class="font-semibold text-[#404553]" for="">
                      {{ $t("profile.linkedIn") }}</label
                    >
                    <FormInput
                      v-model="memberData.linkedin"
                      type="text"
                      class="border-[#dadce3] placeholder:text-sm rounded py-3"
                    />
                  </div>
                </div>
                <div class="relative flex w-full">
                  <div class="flex flex-col gap-2 w-full">
                    <label class="font-semibold text-[#404553]" for="">
                      {{ $t("profile.phoneNumber") }}</label
                    >
                    <FormInput
                      v-model="memberData.phoneNumber_1"
                      type="tel"
                      class="border-[#dadce3] placeholder:text-sm rounded py-3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END: optional info  -->
          <!-- BEGIN: Brief -->
          <div class="col-span-12 intro-y bg-white pb-5">
            <div
              class="flex items-center px-5 py-5 border-b sm:py-5 border-slate-200/60 dark:border-darkmode-400"
            >
              <h2 class="text-base font-medium">
                {{ $t("profile.brief") }}
              </h2>
            </div>
            <div
              class="p-5 grid gap-5 grid-cols-[repeat(auto-fit,minmax(250px,1fr))]"
            >
              <div class="relative flex w-full">
                <div class="flex flex-col gap-2 w-full">
                  <textarea
                    v-model="memberData[objAccessor].brief"
                    type="text"
                    class="border-[#dadce3] placeholder:text-sm rounded py-3"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-12 intro-y bg-white pb-5">
            <div
              class="flex items-center px-5 py-5 border-b sm:py-5 border-slate-200/60 dark:border-darkmode-400"
            >
              <h2 class="text-base font-medium">
                {{ $t("profile.avatar") }}
              </h2>
            </div>
            <div
              class="p-5 grid gap-5 grid-cols-[repeat(auto-fit,minmax(250px,1fr))]"
            >
              <SeoImageInput v-model="memberData[objAccessor].profilePic" />
            </div>
          </div>
          <div
            class="col-span-12 intro-y bg-white pb-5"
            v-show="urlPage.path != '/en/profile'"
          >
            <div class="p-5 intro-y box w-full">
              <SeoMetaFields v-model="memberMetaTags" :isOptional="true" />
            </div>
          </div>

          <div
            class="col-span-12 intro-y bg-white p-5 flex gap-4 w-full justify-end"
          >
            <Button
              type="button"
              variant="outline-secondary"
              class="w-24 mr-1"
              @click="$router.back()"
            >
              {{ $t("common.cancel") }}
            </Button>
            <Button
              type="button"
              variant="primary"
              class="w-24"
              :disabled="loading"
              :isLoading="loading"
              @click="handleSubmit"
            >
              {{ $t("common.save") }}
            </Button>
          </div>

          <!-- END: Brief -->
        </div>
      </div>
    </div>
  </div>
</template>
