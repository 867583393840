import gql from "graphql-tag"


export const GET_KEYWORDS = gql`
 query GetKeyWords($pagination: AlgoliaPagination, $filters: AlgoliaFilters) {
    getKeyWords(pagination: $pagination, filters: $filters) {
      count
      keywords {
        id
        keyword
        occurrences {
          id
          indexName
          slugAr
          slugEn
        }
      }
       
    }
  }

`

export const GET_KEYWORDS_NAMES = gql`
query GetKeyWords($pagination: AlgoliaPagination, $filters: AlgoliaFilters) {
    getKeyWords(pagination: $pagination, filters: $filters) {
      keywords {
        id
        keyword
      }
    }
  }`;
export const GET_KEYWORDS_USAGE = gql`
query getKeywordsUsage {
      getKeyWords{
    count
    keywords {
      id
      keyword
      occurrences {
        id
        indexName
      }
    }
  }
}

`

