import gql from "graphql-tag"
import { recordFragment } from "../fragments/record-fragments"

export const GET_RECORDS = gql`
  query {
    getEmployerOfRecordsPage {
      ...recordFragment
    }
  }
  ${ recordFragment }
`
