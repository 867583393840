import gql from "graphql-tag"
import { recordFragment } from "../fragments/record-fragments"

export const SET_RECORDS= gql`
  mutation UPDATE_RECORDS($data: OutsourcingPageInput!) {
    updateEmployerOfRecordsPage(data: $data) {
      ...recordFragment
    }
  }
  ${ recordFragment }
`
