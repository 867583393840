import { MetaTags } from '@/types/website/home'
import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { camelize, getCurrentInstance, toHandlerKey } from 'vue'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function convertMetaTagsKeywords(metaTags: IMetaTags) {
  function mapKeywords(keywords: any[] | undefined) {
    return keywords?.map((keyword) => {
      console.log("Keyword inside map:", keyword);
      if (typeof keyword === 'string') return keyword || '';
      if (!keyword) return '';
      return keyword.id || '';
    }) || [];
  }

  return {
    ar: { ...metaTags.ar, keywords: mapKeywords(metaTags.ar.keywords) },
    en: { ...metaTags.en, keywords: mapKeywords(metaTags.en.keywords) },
  };
}
