<script setup lang="ts">
import { ref, computed, watchEffect, watch } from "vue"
import { useQuery, useLazyQuery } from "@vue/apollo-composable"
import { GET_KEYWORDS, GET_KEYWORDS_USAGE } from "@/lib/graphql/queries/keywords"
import KeywordsPlacesModal from "@/components/Modals/KeywordsPlacesModal.vue"
import { useI18n } from "vue-i18n";
import { usePagination } from "@/lib/pagination";
import { columns } from "./tableConfig"



interface Keyword {
  id: string
  keyword: string
  count: number,
  places: { name: string, url: string }[]
}

type KeywordOccurrences = {
  id: string
  keyword: string,
  occurrences: {
    indexName: string
    id: string
    slugAr: string,
    slugEn: string
  }[]
}

type KeywordsApi = {
  getKeyWords: {
    keywords: KeywordOccurrences[];
    count: number;
  };
};

const keywords = ref<Keyword[]>([])
const showKeywordsPlacesModal = ref(false)
const selectedKeyword = ref<Keyword>()
const isPlacesLoading = ref(false)
const { loading, error, load, refetch, result } = useLazyQuery<KeywordsApi>(GET_KEYWORDS, {
  fetchPolicy: "no-cache",
})


const { locale } = useI18n();

const getLocalizedSlug = (ocurrence: { slugAr: string, slugEn: string }) => {
  return locale.value === "ar" ? ocurrence.slugAr : ocurrence.slugEn
}
const formatKeywordPlace = (keyword: { indexName: string, id: string, slugAr: string, slugEn: string }) => {
  const baseUrl = import.meta.env.VITE_WEBSITE_URL
  const occurrence = keyword;
  const url = occurrence.indexName === "Blogs"
    ? `${baseUrl}${locale.value}/blogs/${getLocalizedSlug(occurrence)}`
    : occurrence.indexName === "Members"
      ? `${baseUrl}${locale.value}/member/${getLocalizedSlug(occurrence)}`
      : occurrence.indexName === "clients"
        ? `${baseUrl}${locale.value}/clients/${getLocalizedSlug(occurrence)}`
        : occurrence.indexName === "Partners"
          ? `${baseUrl}${locale.value}/partners/${getLocalizedSlug(occurrence)}`
          : occurrence.indexName === "Assessments"
            ? `${baseUrl}${locale.value}/assesments/${getLocalizedSlug(occurrence)}`
            : occurrence.indexName === "Categories"
            ? `${baseUrl}${locale.value}/blogs/category/${getLocalizedSlug(occurrence)}`
            : occurrence.indexName === "FAQs"
            ? `${baseUrl}${locale.value}/faq/${getLocalizedSlug(occurrence)}`
            : `${baseUrl}${locale.value}/pages/${getLocalizedSlug(occurrence)}`
  console.log(url, "url");
  return url
}

const { getPaginationVariables, pagination } = usePagination<KeywordsApi>(
  result,
  "getKeyWords",
  fetchKeywords
)
async function fetchKeywords(): Promise<KeywordsApi | undefined> {
  isPlacesLoading.value = true
  try {
    console.log(getPaginationVariables())
    let response = await load(null, { pagination: getPaginationVariables() })
    if (!response) {
      await refetch({ pagination: getPaginationVariables() })
    }
    console.log("Keyword Usage Response:", response)

    const fetchedKeywords = result.value?.getKeyWords?.keywords
    if (!fetchedKeywords) {
      return
    }
    const formattedKeywords = fetchedKeywords.map((keyword) => ({
      id: keyword.id,
      keyword: keyword.keyword,
      count: keyword.occurrences?.length || 0,
      places: keyword.occurrences?.map((occurrence) => {
        return { name: `${occurrence.indexName}(${getLocalizedSlug(occurrence)})`, url: formatKeywordPlace(occurrence) }
      }),
    }))
    debugger
    keywords.value = formattedKeywords

  } catch (error) {
    console.error("Failed to fetch places:", error)
  } finally {
    isPlacesLoading.value = false
  }
}
fetchKeywords()



const hasError = computed(() => !!error.value)

const openModal = async (keyword: Keyword) => {
  selectedKeyword.value = keyword
  showKeywordsPlacesModal.value = true
}



</script>

<template>
  <div>
    <div v-if="hasError" class="text-center text-red-500">Failed to load keywords</div>
    <div class="mt-5">
      <div class="intro-y w-full">
        <!-- BEGIN: Form Layout -->
        <div class="p-5 intro-y box w-full">
          <!-- Begin Faqs -->
          <!--services Title En and Ar -->
          <data-list tableTitle="routes.keywords" :rows="keywords" :columns="columns" :loading="loading"
            :withPagination="true" :pagination="pagination" class="!px-0 !mt-0">
            <template #default="{ row }">
              <button v-if="row?.count > 0" @click="openModal(row)"
                class="text-white px-4 py-2 rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-300"
                style="background-color: #111827">
                {{ $t('keyword.viewPlaces') }}
              </button>
            </template>
          </data-list>

          <!-- End Faqs -->
        </div>

        <!-- END: Form Layout -->
      </div>
    </div>
    <KeywordsPlacesModal v-if="selectedKeyword" :open="showKeywordsPlacesModal" :keyword="selectedKeyword?.keyword"
      :places="selectedKeyword?.places" :loading="isPlacesLoading" @close="showKeywordsPlacesModal = false" />

  </div>
</template>
