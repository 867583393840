/* eslint-disable */
import moment from "moment";
// import HelperClass from "@/lib/helperClass";
import { getLanguage } from "@/locale/i18n";
import defaultImg from "@/assets/images/No-Image-Placeholder.svg";
import defaultAvatar from "@/assets/images/default-avatar.svg";
// import defaultLogo from "@/assets/images/logo-placeholder.png";
import defaultPlaceholder from "@/assets/images/photoPlaceholder.svg";

const language = getLanguage();

const defaults: Record<string, string> = {
  img: defaultImg,
  avatar: defaultAvatar,
  // logo: defaultLogo,
  placeholder: defaultPlaceholder,
};

export class GenericModel {
  static get fields() {
    throw new Error("Not implemented");
  }
  static isUrl(url: string) {
    const pattern =
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    const result = pattern.test(url);
    return result;
  }

  static presenterImgSrc = (row: any, fieldName: string, img = "img") => {
    if (fieldName.includes(".")) {
      let valWithDot = fieldName.split(".").reduce((acc, prop) => {
        return acc[prop];
      }, row);
      valWithDot = typeof valWithDot === "object" ? valWithDot?.url : valWithDot;
      return valWithDot || defaultImg;
    }
    const val = row != null ? row[fieldName] : null;
    const src =
      val && typeof val === "object"
        ? // ? val.publicUrl
          val?.url
        : val && typeof val === "string"
        ? val
        : "";
    return this.isUrl(src) ? src : defaults[img] || defaultImg;
  };

  static presenter(row: any, fieldName: string) {
    let val;
    if (!fieldName.includes(".")) {
      val = row != null ? row[fieldName] : null;
    } else {
      val = fieldName.split(".").reduce((acc, prop) => {
        return acc[prop];
      }, row);
    }
    return val || "ـــ";
  }

  static getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  static presenterImg = (
    img: Record<string, string> | string,
    type = "img"
  ) => {
    const src =
      img && typeof img === "object"
        ? img.publicUrl
        : img && typeof img === "string"
        ? img
        : "";
    return this.isUrl(src) ? src : defaults[type] || defaultImg;
  };

  // static presenterField(row: any, fieldName: string) {
  //   if (!this.fields[fieldName]) {
  //     throw new Error(`${fieldName} not found`);
  //   }

  //   return this.fields[fieldName].forPresenter(
  //     row[this.fields[fieldName].name]
  //   );
  // }

  static presenterInteger(row: any, fieldName: string) {
    const val = row != null ? row[fieldName] : null;
    return val || 0;
  }

  static presenterDecimal(row: any, fieldName: string, fractionDigits = 2) {
    const val = row != null ? row[fieldName] : null;
    return val ? (Math.round(val * 100) / 100).toFixed(fractionDigits) : 0;
  }

  static presenterLocalization(row: any, fieldName: string) {
    // const val = PlanModel.presenter(row, fieldName);
    const val = row != null ? row[fieldName] : null;
    const valueEn = val && typeof val === "object" ? val.en : null;
    const valueAr = val && typeof val === "object" ? val.ar : null;
    const value =
      language === "ar"
        ? valueAr != null
          ? valueAr
          : valueEn
        : valueEn != null
        ? valueEn
        : valueAr;
    return value != null ? value : "ـــ";
  }
  static presenterSingleLanguage(row: any, fieldName: string, lang: string) {
    // const val = PlanModel.presenter(row, fieldName);
    const val = row != null ? row[fieldName] : null;
    const localizedValue = val && val[lang];
    return localizedValue != null ? localizedValue : "ـــ";
  }
  static present(row: any, fieldName: string, localized?: boolean) {
    return localized
      ? this.presenterLocalization(row, fieldName)
      : this.presenter(row, fieldName);
  }
  //#region [ DATE & TIME ]
  static formatDate(date: any) {
    return moment.unix(date.seconds).format("DD/MM/YYYY");
  }

  static presenterDateTime(row: any, fieldName: string) {
    const val = row != null ? row[fieldName] : null;
    return val
      ? moment(val).locale(language).format("DD MMM, YYYY h:mm A")
      : "ـــ";
  }

  static presenterDate(row: any, fieldName: string) {
    const val = row != null ? row[fieldName] : null;
    return val ? moment(val).locale(language).format("D MMM, YYYY") : "ـــ";
  }

  static presenterDay(row: any, fieldName: string) {
    const val = row != null ? row[fieldName] : null;
    return val ? moment(val).locale(language).format("dddd") : "ـــ";
  }

  static presenterTime(row: any, fieldName: string) {
    const val = row != null ? row[fieldName] : null;
    return val
      ? moment(val, "YYYY-MM-DD'T'hh:mm:ss.SSS'Z'")
          .locale(language)
          .format("hh:mm a")
      : "ـــ";
  }
  //#endregion
}
