<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";
import {
  inject,
  ref,
  ComputedRef,
  computed,
  onMounted,
  watch,
  provide,
} from "vue";
import  ClassicEditor  from "@/base-components/Ckeditor/ClassicEditor.vue";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { GET_BLOGS_CATEGORIES } from "@/lib/graphql/queries/blogsCategories";
import { GET_MEMBERS_DROPMENU } from "@/lib/graphql/queries/members";
import SeoMetaFields from "@/base-components/SeoMetaFields/SeoMetaFields.vue";
import TomSelect from "@/base-components/TomSelect";
import Tab from "@/base-components/Headless/Tab";
import { initBlog } from "../initialblogs";

const { isRTL, language, i18n } = useLocalize();

const BlogData = inject<ComputedRef<IBlog>>("BlogData")!;
const lang = ref<"en" | "ar">(language.value);
const blogsMetaTags = ref();

const metaTagObject = initBlog().metaTags.en;
console.log(metaTagObject, "its meta tags");

watch(
  [() => BlogData.value.metaTags, () => lang.value],
  () => {
    blogsMetaTags.value = BlogData.value.metaTags[lang.value] ?? {
      ...metaTagObject,
    };
  },
  {
    deep: true,
    immediate: true,
  }
);
watch(
  blogsMetaTags,
  () => {
    BlogData.value.metaTags[lang.value] = blogsMetaTags.value;
  },
  {
    deep: true,
    immediate: true,
  }
);

const objAccessor = computed<IJobObject>(() =>
  lang.value === "ar" ? "ar" : "en"
);

provide("BlogData", BlogData);
provide("objAccessor", objAccessor);

//SLUG
const tawzefDomain = import.meta.env.VITE_WEBSITE_URL;
function generateSlug(title: string) {
  const slug = title
    .toLowerCase()
    .trim()
    .replace(/[^\u0600-\u06FFa-z0-9\s-]/g, "") //  Remove all non-alphanumeric characters except spaces and hyphens, including Arabic characters
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/-+/g, "-"); // Replace multiple hyphens with a single hyphen
  BlogData.value[objAccessor.value].slug = slug;
}

function generateBlogLink(slug: string) {
  const blogLink = tawzefDomain + "blogs/" + slug;
  window.open(blogLink, "_blank");
}

const editorConfig = {
  language: "ar",
};
const editorData = ref("");

type BlogCategoryApi = {
  getCategories: {
    category: IBlogCategory[];
    totalRecords: number;
  };
};
// GET BLOG CATEGORIES
const { loading: categoriesLoading, result: categoriesResult } =
  useQuery<BlogCategoryApi>(GET_BLOGS_CATEGORIES, null, {
    fetchPolicy: "no-cache",
  });

const categoriesPlaceholder = computed<string>(() =>
  i18n("blogs.selectCategories")
);

const categoriesList = computed<IBlogCategory[]>(
  () => categoriesResult.value?.getCategories?.category || []
);

//GET MEMBERS
const { loading: membersLoading, result: membersResult } = useQuery<any>(
  GET_MEMBERS_DROPMENU,
  null,
  {
    fetchPolicy: "no-cache",
  }
);

const membersPlaceholder = computed<string>(() => i18n("blogs.selectMembers"));

const membersList = computed<IBlogCategory[]>(
  () => membersResult.value?.getMembers?.member || []
);

const loading = computed<boolean>(
  () => categoriesLoading.value || membersLoading.value
);
</script>

<template>
  <Tab.Group>
    <Tab.List variant="boxed-tabs">
      <Tab>
        <Tab.Button class="w-full py-2" as="button" @click="lang = 'en'">
          {{ $t("common.english") }}
        </Tab.Button>
      </Tab>
      <Tab>
        <Tab.Button class="w-full py-2" as="button" @click="lang = 'ar'">
          {{ $t("common.arabic") }}
        </Tab.Button>
      </Tab>
    </Tab.List>
  </Tab.Group>
  <div class="pb-4 my-5 intro-y border-b-2 border-b-slate-100">
    <h2 class="text-base">
      {{ $t("blogs.blogInformations") }}
    </h2>
  </div>

  <div class="grid-cols-2 gap-3 mt-3 sm:grid">
    <div>
      <FormLabel htmlFor="crud-form-1"> {{ $t("blogs.title") }}</FormLabel>
      <FormInput
        @input="generateSlug(BlogData[objAccessor].title)"
        id="crud-form-1"
        type="text"
        class="w-full"
        v-model="BlogData[objAccessor].title"
      />
    </div>

    <div>
      <FormLabel htmlFor="crud-form-1"> {{ $t("blogs.slug") }}</FormLabel>
      <div class="flex items-center">
        <button
          @click="generateBlogLink(BlogData[objAccessor].slug)"
          class="bg-zinc-200 text-xs h-9 text-slate-600 ps-3 pe-1 py-1.5 rounded-s-md border border-slate-300 border-e-0"
        >
          {{ tawzefDomain + "blogs/" }}
        </button>
        <FormInput
          @input="generateSlug(BlogData[objAccessor].slug)"
          type="text"
          name=""
          id=""
          v-model="BlogData[objAccessor].slug"
          class="border-s-0 focus:outline-none h-9 border-slate-300 focus:ring-0 rounded-s-none"
        />
      </div>
    </div>
  </div>

  <div class="mt-3">
    <FormLabel htmlFor="crud-form-1"> {{ $t("common.media") }}</FormLabel>
    <SeoImageInput class="mb-4" v-model="BlogData[objAccessor].image" />
  </div>
  <div class="mt-3">
    <FormLabel htmlFor="crud-form-1"> {{ $t("common.thumbnail") }}</FormLabel>
    <SeoImageInput class="mb-4" v-model="BlogData[objAccessor].thumbnail" />
  </div>

  <div class="my-5">
    <FormLabel htmlFor="crud-form-2">{{ $t("blogs.category") }}</FormLabel>

    <TomSelect
      v-model="BlogData.categories"
      :options="{
        placeholder: categoriesPlaceholder,
      }"
      class="w-full"
      multiple
      :create="false"
    >
      <option
        disabled
        value="loading"
        v-if="loading"
        style="text-align: center"
      >
        Loading...
      </option>
      <template v-else>
        <option
          v-for="category in categoriesList"
          :key="category.id"
          :value="category.id"
        >
          {{ category.name.en }} - {{ category.name.ar }}
        </option>
      </template>
    </TomSelect>
  </div>

  <div class="mt-3">
    <FormLabel htmlFor="crud-form-2">{{ $t("blogs.createdBy") }}</FormLabel>
    <TomSelect
      v-model="BlogData.writenBy"
      :options="{
        placeholder: membersPlaceholder,
      }"
      class="w-full"
      multiple
      :create="false"
    >
      <option
        disabled
        value="loading"
        v-if="loading"
        style="text-align: center"
      >
        Loading...
      </option>
      <template v-else>
        <option
          v-for="member in membersList"
          :key="member.id"
          :value="member.id"
        >
          {{ member?.email }}
        </option>
      </template>
    </TomSelect>
  </div>

  <div class="mt-3 gap-3 grid-cols-1 sm:grid">
    <div v-if="lang === 'ar'">
      <label>{{ $t("common.descAR") }}</label>
      <div class="mt-2" :key="loading">
        <!-- {{ BlogData.body.ar }} -->
        <!-- <ClassicEditor v-model="BlogData.ar.body" :config="editorConfig" /> -->
        <ClassicEditor v-model="BlogData.ar.body" />
      </div>
    </div>

    <div v-else>
      <label>{{ $t("common.descEN") }}</label>
      <div class="mt-2" :key="loading">
        <!-- <ClassicEditor v-model="BlogData.en.body" :config="editorConfig" /> -->
        <ClassicEditor v-model="BlogData.en.body" />
      </div>
    </div>
  </div>

  <SeoMetaFields v-model="blogsMetaTags" :isOptional="true" />
</template>
