<script setup lang="ts">
import { FormInput, FormLabel } from "@/base-components/Form";
import { useLocalize } from "@/locale/i18n";
import { inject, ref, ComputedRef, computed } from "vue";
import SelectKeywords from './SelectKeywords.vue'
import Dropzone from "@/base-components/Dropzone";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { MetaTagsStructure } from "@/types/website/home";
import { SocialType } from "@/types/website/home";

const metaFields = defineModel<MetaTagsStructure>({
  required: true,
  default: {
    keywords: []
  },
});
export interface Props {
  isOptional?: boolean;
}

const props = withDefaults(defineProps<Props>(), { isOptional: false });

const handleAddItem = () => {
  metaFields.value.social.push({
    title: "",
    description: "",
    image: { title: "", alt: "", caption: "", description: "", url: "" },
    socialNetwork: SocialType.Facebook,
  });
};
const handleRemoveItem = (index: number) => {
  metaFields.value.social.splice(index, 1);
};
const homePageData = inject<IHomePage>("homePageData", {} as IHomePage);
const objAccessor = inject<ComputedRef<IHomeObject> | IHomeObject>(
  "objAccessor",
  "EnHomePage"
);
const accessor = computed<IHomeObject>(() => {
  if (typeof objAccessor === "string") return objAccessor;
  else return objAccessor.value;
});
const iconsArray = ref<IImage[]>(
  homePageData[accessor.value]?.images || [
    { title: "icon 1", alt: "", caption: "", description: "", url: "" },
  ]
);
// const metaFields = ref({
//   keywords: []
// });
</script>
<template>
  <div>
    <h2 class="text-lg my-6 font-medium">
      {{ $t("common.seo") }}
      <span class="text-slate-500 text-[11px] ml-1" v-if="isOptional">
        {{ $t("common.optional") }}
      </span>
    </h2>
  </div>

  <div class="mt-6">
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("seo.metaTitle") }}</FormLabel>
        <FormInput id="crud-form-1" type="text" class="w-full" v-model="metaFields.title" />
      </div>
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("seo.MetaDescription") }}</FormLabel>
        <FormInput id="crud-form-1" type="text" class="w-full" v-model="metaFields.description" />
      </div>
    </div>
  </div>
  <div class="mt-6">
    <div class="grid-cols-2 gap-3 mt-2 sm:grid">
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("seo.canonical") }}</FormLabel>
        <FormInput id="crud-form-1" type="text" class="w-full" v-model="metaFields.canonical" />
      </div>
      <div>
        <FormLabel class="font-medium mt-2" htmlFor="crud-form-1">
          {{ $t("seo.metaRobots") }}</FormLabel>
        <FormInput id="crud-form-1" type="text" class="w-full" v-model="metaFields.robots" />
      </div>
    </div>
  </div>
  <div class="my-4">
    <h2 class="font-medium">
      {{ $t("seo.keywords") }}
    </h2>
  </div>
  <div :key="metaFields.keywords">
    <SelectKeywords v-model="metaFields.keywords" />
  </div>
  <div class="my-3">
    <h2 class="font-medium">
      {{ $t("seo.metaSocials") }}
    </h2>
  </div>
  <div class="grid-cols-1 sm:grid gap-5 mt-3">
    <DraggableList v-if="metaFields.social?.length > 0" @removeItem="handleRemoveItem" @addItem="handleAddItem"
      :theArray="metaFields.social"><template v-slot="{ index }">
        <div class="mt-3">
          <div class="grid-cols-2 gap-3 sm:grid">
            <div>
              <FormLabel class="!text-slate-400 text-xs font-medium mt-2" htmlFor="crud-form-1">
                {{ $t("seo.title") }}*</FormLabel>
              <FormInput id="crud-form-1" type="text" class="w-full" v-model="metaFields.social[index].title" />
            </div>
            <div>
              <FormLabel class="!text-slate-400 text-xs font-medium mt-2" htmlFor="crud-form-1">
                {{ $t("seo.description") }}*</FormLabel>
              <FormInput id="crud-form-1" type="text" class="w-full" v-model="metaFields.social[index].description" />
            </div>
          </div>
        </div>
        <div class="grid-cols-2 gap-3 mt-2 sm:grid">
          <div>
            <FormLabel class="!text-slate-400 text-xs font-medium mt-2" htmlFor="crud-form-1">
              {{ $t("seo.socialNetwork") }}*</FormLabel>
            <Select v-model="metaFields.social[index].socialNetwork" class="shadow-none justify-start">
              <SelectTrigger class="border-slate-200 border flex gap-4">
                <div class="flex gap-1.5 py-1">
                  <SelectValue />
                </div>
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem :value="SocialType.Facebook">
                    Facebook
                  </SelectItem>
                  <SelectItem :value="SocialType.Twitter"> Twitter </SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div>
            <FormLabel class="!text-slate-400 text-xs font-medium mt-2" htmlFor="crud-form-1">
              {{ $t("seo.image") }}*</FormLabel>
            <SeoImageInput v-model="metaFields.social[index].image" />
          </div>
        </div>
      </template>
    </DraggableList>
  </div>
  <!--  -->
  <!-- End Hero Title & paragraph-->
</template>
